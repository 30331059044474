import { ProductType } from "../../../types";
import { shopify_get_products_by_tag } from "../../../utils/helper/shopify_get_products_by_tag";

export default async function shopproducts_by_tag(shop):Promise<{err: string | undefined, res: ProductType[] | undefined}> {
  const xx = await shopify_get_products_by_tag(shop)
  .catch((e: any) => {
    console.error(e);
    return undefined
  });
  if (xx !== undefined) {
    return xx
      .json()
      .then(async (res: any) => {
        // const x = await res.test;
        //setErrorMessage('')
        if (!res.success) {

          return {
            res: undefined,
            err: "Problem connecting the Keepoala Server",
          };
        }

        return {
          res: res.products,
          err: undefined
        }
      })
      .catch((e: any) => {
        //setErrorMessage(t('Problem connecting the Keepoala Server'))
        return { res: undefined, err: "Problem connecting the Keepoala Server" };
      });
  } else {
    return {
      res: undefined,
      err: "Problem connecting the Keepoala Server"
    }
  }
}