import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "./getEndpointUrl";

export async function shopify_get_products_by_tag(shop) {
  const req_body = JSON.stringify({ shop: shop });
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(req_body, process.env.REACT_APP_ENV_SHOPIFY_LINE_ITEMS)),
    },
    body: req_body,
  };
  // return await fetch( 'http://localhost:8100/127.0.0.1:5001/keepoalatesting/europe-west1/shopify_get_dhl_detail', requestOptions)
  return await fetch(getEndpointUrl() + "/shopify_get_products_by_tag", requestOptions);
}
