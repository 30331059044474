import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InterStyled from "../../styles/InterStyled";
import StepperButtons from "./StepperButtons";
import ReturnQuestions, { AnswerType } from "../ReturnQuestions";
import { firestore_get_nps } from "../../utils/helper/firestore_get_nps";
import LoadingCircular from "../LoadingCircular";
import { ProductType } from "../../types";

export default function ReturnReasons(props: {
  onContinuePressed: () => void;
  onBackPressed: () => void;
  product: ProductType | undefined;
  shop: string | undefined;
  setAnswers: (answers: AnswerType[]) => void;
}) {
  const { t } = useTranslation();

  const [loadingNPSandTreeCategory, setLoadingNPSandTreeCategory] =
    useState<boolean>(false);
  const [NPSandTreeCategory, setNPSandTreeCategory]: [
    { nps: boolean; tree: any; nps_tree: any } | undefined,
    (x: any) => void
  ] = useState();

  const [answersInternal, setAnswersInternal] = useState<AnswerType[]>([]);

  useEffect(() => {
    if (NPSandTreeCategory === undefined && props.shop !== undefined) {
      // Set loading to show loading bar
      setLoadingNPSandTreeCategory(true);
      firestore_get_nps(props.shop)
        .then((response) => response.json())
        .then((data) => {
          setNPSandTreeCategory(data);
          setLoadingNPSandTreeCategory(false);
        })
        .catch((error) => console.log(error));
    }
  }, [NPSandTreeCategory, props.shop]);

  const cat =
    NPSandTreeCategory?.tree[props.product?.product_type ?? "default"] ??
    NPSandTreeCategory?.tree.default;

  return (
    <div>
      <InterStyled interStyle="Label 1">{t("Return reasons")}</InterStyled>
      <div style={{ height: 20 }} />
      {loadingNPSandTreeCategory ? (
        <LoadingCircular />
      ) : (
        <ReturnQuestions
          productName={props.product?.title}
          productVariantID={props.product?.variant_id.toString() ?? ""}
          successHandler={(answers) => {
            props.setAnswers(answers);
            setAnswersInternal(answers);
            props.onContinuePressed();
          }}
          skipStart={true}
          skipEnd={true}
          category={cat}
          shop={props.shop ?? ""}
          shopId={props.shop ?? ""}
          orderID={""}
          userID=""
          orderName=""
        />
      )}
      <div style={{ height: 20 }} />
      <StepperButtons
        onContinuePressed={
          answersInternal === undefined || answersInternal.length === 0
            ? undefined
            : props.onContinuePressed
        }
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
