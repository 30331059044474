import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);

export default function AlertError(props: {
  errorMessage: string;
  with_contact: boolean;
  contact?: string;
}): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Alert mb={4} mt={4} severity="error">
      {props.errorMessage}
      {props.with_contact !== true ? null : (
        <>
          <br />
          {t("Please contact the support at")}{" "}
          <a href={`mailto:${props.contact ?? "retourenportal@keepoala.com"}`}>
            {props.contact ?? "retourenportal@keepoala.com"}
          </a>
        </>
      )}
    </Alert>
  );
}

AlertError.defaultProps = {
  errorMessage: "error",
  contact: "retourenportal@keepoala.com",
  with_contact: true,
};
