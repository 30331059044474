import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import split_query from "../../utils/helper/split_query";
import { useTranslation } from "react-i18next";
import LoadingCircular from "../LoadingCircular";
import ProgressStepper from "../ProgressStepper";
import PurchaseEmail from "./PurchaseEmail";
import ProductSelection from "./ProductSelection";
import DateOfPurchase from "./DateOfPurchase";
import AddressDetails from "./AddressDetails";
import ReceiptUpload, { UrlAnswerType } from "./ReceiptUpload";
import ReturnReasons from "./ReturnReasons";
import { ProductType } from "../../types";
import { AddressFormData } from "./AddressForm";
import shop_exchange_query from "./API/shop_exchange_query";
import { ShopifyLineItemForPortal, ShopifyProduct } from "../../utils/helper/LineItemTypes";
import ReturnExchangeWrapper from "./ReturnExchangeWrapper";
import KeepoalaThemeGetter from "../../styles/KeepoalaThemeGetter";
import { Container } from "../../components/ReturnFeedback";
import styled from "styled-components";
import { Alert } from "@mui/material";
import { AnswerType } from "../ReturnQuestions";
import DoneScreen from "./DoneScreen";
import { setOrderInFirestore } from "./API/setOrderInFirestore";
import { createTypeform } from "./API/createTypeform";
import ReturnLabel from "../ReturnLabel";

const ContainerManual = styled(Container)`
  flex-grow: 1;
  display: flex;
  min-height: 70vh;
  padding-left: 1em;
  padding-right: 1em;
  & div.visComponent {
    display: flex;
    flex-grow: 1;
  }
  & div.visComponent > div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    & div.visComponent {
      width: 95%;
    }
  }
`;

export default function ReturnFeedbackManual() {
  const { search } = useLocation();
  const query_params: { shop?: string; location?: string } = split_query(search);
  const { t, i18n } = useTranslation();

  const [current_step, setCurrentStep] = useState<number>(0);

  const shop_name = query_params.shop ?? "none";
  const location = query_params.location ?? "DM";

  const [email, setEmail] = useState<string>();

  const [selected_product, setSelectedProduct] = useState<ProductType>();

  const [date_of_purchase, setDateOfPurchase] = useState<Date>();

  const [address, setAddress] = useState<AddressFormData>({
    name: "",
    address1: "",
    address2: "",
    zip: "",
    city: "",
    country: "DE",
  });

  const [receipt, setReceipt] = useState<UrlAnswerType>({ urls: [""], text: "" });

  const [exchange_items, setExchangeItems] = useState<ShopifyLineItemForPortal>();

  const [exchange_error, setExchangeError] = useState<string>("");

  const [exchange_wishes, setExchangeWishes] = useState<(ShopifyProduct | undefined)[]>();

  const [answers, setAnswers] = useState<AnswerType[]>();

  const [order_id, setOrderId] = useState<string>();

  const incrementStep = useCallback(() => {
    setCurrentStep((c_step) => c_step + 1);
  }, []);

  const decrementStep = useCallback(() => {
    setCurrentStep((c_step) => c_step - 1);
  }, []);

  const [finished, setFinished] = useState<boolean>(false);

  const finish = useCallback((): Promise<boolean> => {
    if (
      email !== undefined &&
      answers !== undefined &&
      selected_product !== undefined &&
      date_of_purchase !== undefined
    ) {
      return setOrderInFirestore(email, shop_name, address, selected_product, date_of_purchase, location).then(
        (result) => {
          if (result.success === true) {
            return createTypeform(
              shop_name,
              email,
              address,
              selected_product,
              date_of_purchase,
              location,
              receipt,
              answers,
              exchange_wishes ?? [],
              result.order_id
            ).then((upload_result) => {
              if (upload_result === true) {
                setOrderId(result.order_id);
                setFinished(true);
                return true;
                // Finish
              } else {
                // Handle upload typeform error
                setExchangeError(t("We had trouble uploading your stuff, please try again"));
                return false;
              }
            });
          } else {
            setExchangeError(t("We had trouble uploading your stuff, please try again"));
            return false;
          }
        }
      );
    } else {
      return new Promise((resolve, reject) => resolve(false));
    }
  }, [
    shop_name,
    selected_product,
    date_of_purchase,
    address,
    receipt,
    answers,
    location,
    email,
    // exchange_items,
    exchange_wishes,
    t,
    setOrderId,
  ]);

  const visibleComponent = useCallback(() => {
    switch (current_step) {
      case 0:
        return <PurchaseEmail email={email} setEmail={setEmail} onContinuePressed={incrementStep} />;
      case 1:
        return (
          <ProductSelection
            shop_name={shop_name ?? ""}
            selected_product={selected_product}
            setSelectedProduct={setSelectedProduct}
            onContinuePressed={incrementStep}
            onBackPressed={decrementStep}
          />
        );
      case 2:
        return (
          <DateOfPurchase
            date_of_purchase={date_of_purchase}
            setDateOfPurchase={setDateOfPurchase}
            onContinuePressed={incrementStep}
            onBackPressed={decrementStep}
          />
        );
      case 3:
        return (
          <AddressDetails
            address={address}
            setAddress={setAddress}
            onContinuePressed={incrementStep}
            onBackPressed={decrementStep}
          />
        );
      case 4:
        return (
          <ReceiptUpload
            onContinuePressed={incrementStep}
            onBackPressed={decrementStep}
            shopName={shop_name}
            value={receipt}
            setValue={setReceipt}
          />
        );
      case 5:
        return (
          <ReturnReasons
            onContinuePressed={incrementStep}
            onBackPressed={decrementStep}
            product={selected_product}
            shop={shop_name}
            setAnswers={setAnswers}
          />
        );
      case 6:
        return (
          <ReturnExchangeWrapper
            onBackPressed={decrementStep}
            onContinuePressed={incrementStep}
            exchange_items={exchange_items}
            value={exchange_wishes}
            setValue={setExchangeWishes}
          />
        );
      case 7:
        return (
          <DoneScreen
            product={selected_product}
            exchange_wishes={exchange_wishes}
            answers={answers}
            date_of_purchase={date_of_purchase}
            location={location}
            address={address}
            email={email}
            onBackPressed={decrementStep}
            onContinuePressed={() => finish()}
          />
        );
      default:
        break;
    }
  }, [
    current_step,
    shop_name,
    selected_product,
    date_of_purchase,
    address,
    incrementStep,
    decrementStep,
    receipt,
    exchange_items,
    exchange_wishes,
    answers,
    location,
    email,
    finish,
  ]);

  useEffect(() => {
    if (date_of_purchase !== undefined && shop_name !== undefined && selected_product !== undefined) {
      shop_exchange_query(shop_name, selected_product.variant_id, i18n.language).then(
        (value: { res: ShopifyLineItemForPortal | undefined; err: string | undefined }) => {
          console.log(value);
          if (value.res !== undefined) {
            setExchangeItems(value.res);
            setExchangeError("");
          } else {
            setExchangeError(JSON.stringify(value.err));
          }
        }
      );
    }
  }, [date_of_purchase, shop_name, selected_product, i18n.language]);

  return (
    <KeepoalaThemeGetter shop={query_params.shop ?? "none"}>
      {query_params.shop === undefined ? (
        <LoadingCircular />
      ) : !finished ? (
        <ContainerManual maxWidth="sm" className="cardbox">
          <ProgressStepper
            steps={[
              t("Purchase Email"),
              t("Product"),
              t("Date of Purchase"),
              t("Address"),
              t("Receipt"),
              t("Reasons"),
              t("Summary"),
            ]}
            activeStep={current_step}
          />
          <div style={{ height: 30 }} />
          <div className="visComponent">{visibleComponent()}</div>
          {exchange_error !== "" && (
            <Alert style={{ marginTop: 20 }} severity="error">
              {exchange_error}
            </Alert>
          )}
        </ContainerManual>
      ) : (
        <ContainerManual maxWidth="sm" className="cardbox">
          {order_id !== undefined && (
            <ReturnLabel
              shop={shop_name}
              id={order_id?.toString()}
              email={email}
              isUser={false}
              has_exchanges={true}
              special_info=""
              special_working_days={[5, 10]}
            />
          )}
        </ContainerManual>
      )}
    </KeepoalaThemeGetter>
  );
}
