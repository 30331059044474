import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Box, Button, CircularProgress, List, ListItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InterStyled from "../../styles/InterStyled";
import StepperButtons from "./StepperButtons";
// import { search_shopproducts } from "../../utils/helper/search_shopproducts";
import { useRateLimiter } from "../../utils/helper/DebounceRateLimit";
import { ProductType } from "../../types";
import { Clickable } from "../Banner1_Welcome";
import ProductPreview from "./ProductPreview";
// import fetchQuery from "./API/shopproducts_query";
import shopproducts_by_tag from "./API/shopproducts_by_tag";

export default function ProductSelection(props: {
  selected_product: ProductType | undefined;
  setSelectedProduct: Dispatch<SetStateAction<ProductType | undefined>>;
  shop_name: string;
  onContinuePressed: () => void;
  onBackPressed?: () => void | undefined;
}) {
  const { t } = useTranslation();

  const { selected_product, setSelectedProduct } = props;

  const [product_search, setProductSearch] = useState("");
  const [products_all, setProductsAll] = useState<ProductType[]>([]);
  const [product_search_result, setProductSearchResult] = useState<ProductType[]>([]);

  const [searching, setSearching] = useState(false);

  const [debounced_value, setDebouncedValue] = useState("");

  const fetchProducts = (shop) => {
    setSearching(true);
    shopproducts_by_tag(shop)
      .then((value) => {
        console.log(value);
        if (value.res !== undefined) {
          setProductsAll(value.res);
          setProductSearchResult(value.res);
        } else {
          console.log("Error occurred while fetching shop products " + value.err);
        }
      })
      .finally(() => {
        setSearching(false);
      });
  };

  function filterByTitle(products: ProductType[], productSearch: string) {
    return products.filter((product) => product.title.toLowerCase().includes(productSearch.toLowerCase()));
  }
  // Define your API call function
  const fetchData = () => {
    if (products_all.length > 0 && product_search !== "") {
      setProductSearchResult(filterByTitle(products_all, product_search));
    }
  };

  // Wrap the fetchData function with rate limiter
  const rateLimitedFetchData = useRateLimiter(fetchData, 5); // 5 calls maximum per minute

  useEffect(() => {
    // Debounce logic
    const handler = setTimeout(() => {
      setDebouncedValue(product_search);
    }, 600); // 600ms debounce delay

    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_search, props.shop_name]);

  useEffect(() => {
    // Call the rate-limited API function when the debounced value changes
    rateLimitedFetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced_value]);

  useEffect(() => {
    if (products_all.length === 0) {
      fetchProducts(props.shop_name);
    }
  }, [props.shop_name, products_all]);

  return (
    <div style={{ width: "auto" }}>
      <InterStyled interStyle="Label 1">{t("What did you buy") + "?"}</InterStyled>
      <div style={{ height: 20 }} />
      {selected_product !== undefined ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}>
          <ProductPreview product={selected_product} with_tick={true} />
          <div style={{ height: 10 }} />
          <Button
            onClick={() => {
              setSelectedProduct(undefined);
            }}>
            {t("Reset Selection")}
          </Button>
        </div>
      ) : (
        <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
          <TextField
            style={{ width: "100%" }}
            value={product_search}
            onChange={(event) => {
              setProductSearch(event.target.value);
            }}
            label={t("Search")}
            placeholder={t("Search Product title")}
          />
          <div style={{ height: 20 }} />
          {searching ? (
            <div style={{ alignSelf: "center" }}>
              <CircularProgress />
            </div>
          ) : product_search_result.length === 0 ? (
            <p>No products found</p>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: 250,
                overflowY: "auto",
                border: "1px solid #ccc",
                bgcolor: "background.paper",
                borderRadius: 2,
                flexGrow: 1,
                display: "flex",
              }}>
              <List style={{ padding: 10 }}>
                {product_search_result.map((item, index) => (
                  <ListItem key={index}>
                    <Clickable
                      style={{
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                      role="button"
                      onClick={() => {
                        setSelectedProduct(item);
                      }}>
                      <ProductPreview product={item} />
                    </Clickable>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </div>
      )}
      <div style={{ height: 20 }} />
      <StepperButtons
        onContinuePressed={selected_product === undefined ? undefined : props.onContinuePressed}
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
